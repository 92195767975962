import Home from "./pages";
import { useLayoutEffect } from "react";
function App() {
  useLayoutEffect(() => {
    document.title = "Rodrigo Software Engineer";
    const metaDescription =
      document.getElementsByTagName("meta")["description" as unknown as number];
    if (metaDescription) {
      metaDescription.content = "Software engineer solutions";
    }
  }, []);
  return (
    <>
      <Home />;
    </>
  );
}

export default App;
