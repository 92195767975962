/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { HomeStyles, ModalStyles } from "../style/home.Styles";
import { Container, Button, Switch } from "@material-ui/core";
import { FaGithubAlt, FaLinkedin, FaWindowClose } from "react-icons/fa";
import { DialogActions } from "@mui/material";
import Typed from "react-typed";

const CV = require("../img/RodrigoCV.png");
const myPDF = require("./rod.pdf");
const caixa = require("../img/react_caixa.png");
const react_icons = require("../img/react_all_icons.png");
const react = require("../img/react.png");

const getWidth = () => {
  return window.innerWidth;
};

const Home = () => {
  const [screenSize, handleScreenSize] = useState(getWidth());
  const [bgColor, handleBgColor] = useState(false);
  const [open, handleOpen] = useState(false);
  const [showHaste, handleHaste] = useState(0);

  useEffect(() => {
    function handleResize() {
      handleScreenSize(getWidth());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  useEffect(() => {
    const timer = setInterval(() => {
      handleHaste((progress) => (progress === 100 ? 100 : showHaste + 5));
    }, 20);
    return () => {
      clearInterval(timer);
    };
  }, [showHaste]);

  useEffect(() => {
    if (!bgColor) {
      return document.body.setAttribute("style", "background: #1D2B35");
    } else {
      return document.body.setAttribute("style", "background: #fff");
    }
  }, [bgColor]);

  return (
    <HomeStyles className={`home-background ${bgColor && "active"}`}>
      <section className={`main-section ${bgColor && "active"}`}>
        <Container maxWidth="lg">
          <div className="container">
            <div>
              <h1 className={`main-header ${bgColor && "active"}`}>Hi,</h1>
              <h1 className={`main-header ${bgColor && "active"}`}>
                I'm Rodrigo.
              </h1>
              <h1 className={`main-header ${bgColor && "active"}`}>
                I'm a Front-end
              </h1>
              <Typed
                className={`main-header ${bgColor && "active"}`}
                strings={["Developer.", "Software Engineer."]}
                typeSpeed={80}
                backSpeed={100}
                loop
              />
            </div>
            <Switch
              className="switch"
              checked={bgColor}
              onChange={() => handleBgColor(!bgColor)}
              name="loading"
              color="primary"
            />
          </div>
          <div className="button-div">
            <Button
              variant="contained"
              className={`btn-primary ${bgColor ? " active" : ""}`}
              onClick={() => handleOpen(true)}
            >
              <a>View my Resume</a>
            </Button>
            <Button
              variant="contained"
              className={`btn-primary ${bgColor ? "active" : ""}`}
            >
              <a href="mailto:souza-fernandouk@hotmail.com">Contact</a>
            </Button>
          </div>
        </Container>
        <Container>
          {screenSize > 425 ? (
            <div className="img-container">
              <img className="img-style bounce" src={caixa} alt="React logo" />
              {showHaste === 100 && (
                <img
                  className={`img-style-icons App-logo`}
                  src={react_icons}
                  alt="React logo"
                />
              )}
            </div>
          ) : (
            <div className="img-mobile">
              <img className="img-style" src={react} alt="React logo" />
            </div>
          )}
          <ul className={`icons ${bgColor && "active"}`}>
            <li>
              <a
                className={`icon ${bgColor && "active"}`}
                href="https://github.com/Rodrigo-De-Souza"
              >
                <FaGithubAlt />
              </a>
            </li>
            <li>
              <a
                className={`icon ${bgColor && "active"}`}
                href="https://www.linkedin.com/in/rodrigo-f-de-souza-a8965a1ba/"
              >
                <FaLinkedin />
              </a>
            </li>
          </ul>
        </Container>
      </section>

      <ModalStyles open={open} onClose={() => handleOpen(false)}>
        <div className="close-div">
          <FaWindowClose
            className="close-icon"
            onClick={() => handleOpen(false)}
          />
        </div>

        <img src={CV} alt="React logo" width="100%" className="resume" />
        <DialogActions>
          <Button onClick={() => handleOpen(false)} className="btn-download">
            <a
              href={myPDF}
              download="Rodrigo_CV.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </Button>
        </DialogActions>
      </ModalStyles>

      {/* <section className={`main-section ${!bgColor && "active"}`}>
        <Container className="container-flex">
          <div className="about-me">
            <h1 className={`main-header ${!bgColor && "active"}`}>About me</h1>
            <p className={bgColor ? "active" : ""}>
              I love solving problems and since a started my carrer as a
              software Engineer I found exacly want I was looking for. I have
              excellent communication skills and always exicted about new
              tecnologia.
              <br />
              Daily I spend more time coding than doing anything else.
            </p>
            <p className={bgColor ? "active" : ""}></p>
          </div>
          <div className="img-aboutUs">
            <img src={softwareEngineer} alt="React logo" />
          </div>
        </Container>
      </section> */}
    </HomeStyles>
  );
};

export default Home;
