import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

export const HomeStyles = styled("div")({
  ".main-section": {
    backgroundColor: "#1D2B35",
    display: "block",
    "&.active": {
      backgroundColor: "#fff",
    },
    "@media(min-width:1024px)": {
      overflow: "hidden",
    },
  },
  ".home-background": {
    height: "100%",
    backgroundColor: "#1D2B35 !important",
    "&.active": {
      backgroundColor: "#fff",
    },
  },
  ".container": {
    paddingTop: "42px",
    display: "flex",
    position: "relative",
  },
  ".main-header": {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: "3rem",
    color: "#fff",
    fontFamily: "Roboto",
    margin: "0px",
    padding: "0px",
    "@media(max-width: 321px)": {
      fontSize: "34px !important",
    },
    "@media(max-width: 425px)": {
      fontSize: "2.2rem",
    },
    "&.active": {
      color: "#1D2B35",
    },
  },
  ".switch": {
    position: "absolute",
    right: "-12px",
  },
  ".MuiSwitch-switchBase": {
    color: "#fff !important",
  },
  ".MuiSwitch-switchBase.Mui-checked": {
    color: "#1D2B35 !important",
  },
  ".MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#1D2B35 !important",
  },
  ".MuiSwitch-track": {
    backgroundColor: "#fff !important",
  },
  ".img-container": {
    height: "21rem",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    "@media(min-width: 1900px)": {
      paddingTop: "10%",
    },
  },
  ".img-mobile": {
    display: "flex",
    justifyContent: "center",
    padding: "32px 0px",
    position: "relative",
  },
  ".img-style": {
    zIndex: 98,
    maxWidth: "100%",
    height: "auto",
    padding: "0px",
    margin: "0px",
    "@media(max-width: 320px)": {
      width: "280px",
      height: "280px",
    },
    "@media(min-width: 375px)": {
      width: "300px",
      height: "300px",
    },
    "@media(min-width: 425px)": {
      width: "250px",
      height: "250px",
    },
    "@media(min-width: 1900px)": {
      width: "350px",
      height: "350px",
    },
  },
  ".img-style-icons": {
    opacity: "1",
    maxWidth: "100%",
    height: "auto",
    padding: "0px",
    margin: "0px",
    "@media(max-width: 320px)": {
      width: "280px",
      height: "280px",
    },
    "@media(min-width: 375px)": {
      width: "335px",
      height: "340px",
    },
    "@media(min-width: 425px)": {
      width: "350px",
      height: "350px",
    },
  },
  ".button-div": {
    paddingTop: "14px",
  },
  ".btn-primary": {
    padding: "0px",
    backgroundColor: "#1D2B35",
    fontSize: "0.9rem",
    marginRight: "20px",
    borderRadius: "2px",
    "@media(max-width: 320px)": {
      fontSize: "12px",
    },
    a: {
      borderRadius: "2px",
      color: "#fff",
      padding: "6px 16px",
      border: "1px solid #fff",
      display: "block",
      transition: "0.4s",
      "&:hover": {
        color: "#000",
        backgroundColor: "#fff",
      },
    },
    "&.active": {
      a: {
        transition: "0.4s",
        color: "#1D2B35",
        backgroundColor: "#fff",
        border: "1px solid #1D2B35 !important",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#1D2B35",
        },
      },
    },
  },

  ".btn-secondary": {
    backgroundColor: "#000",
    a: {
      color: "#000",
      border: "1px solid #C4E2CA !important",
    },
  },
  ".container-flex": {
    display: "flex",
    "@media(max-width: 425px)": {
      display: "block !important",
      paddingTop: "8px",
    },
  },
  ".img-aboutUs": {
    "@media(min-width: 426px)": {
      display: "flex",
      width: "50%",
      justifyContent: "center",
    },
    img: {
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "8px",
    },
  },
  ".about-me": {
    "@media(min-width: 426px)": {
      width: "55%",
    },
    p: {
      color: "#1D2B35",
      "&.active": {
        color: "#fff",
      },
    },
  },
  ".icons": {
    display: "flex",
    alignItems: "center",
    listStyleType: "none",
    fontSize: "34px",
    justifyContent: "center",
    ".icon": {
      paddingRight: "20px",
      color: "#fff",
      opacity: "0.7",
      display: "block",
      transition: "0.4s",
      "&.active": {
        color: "#1D2B35",
      },
      "&:hover": {
        opacity: "1",
        cursor: "pointer",
      },
    },
    "@media(min-width: 1900px)": {
      paddingTop: "66px",
    },
  },
});

export const ModalStyles = styled(Dialog)({
  ".btn-download": {
    backgroundColor: "#587D9F",
    opacity: "0.8",
    transition: "0.5s",
    "&:hover": {
      cursor: "pointer",
      opacity: "1",
    },
    a: {
      color: "#fff",
    },
  },
  ".close-div": {
    height: "10px",
  },
  ".close-icon": {
    position: "absolute",
    right: "8px",
    top: "8px",
    opacity: "0.8",
    transition: "0.5s",
    "&:hover": {
      cursor: "pointer",
      opacity: "1",
    },
  },
  ".resume": {
    width: "100%",
    height: "90%",
  },
});
